import React from 'react';
import styled from 'styled-components';
import { FluidObject } from 'gatsby-image';
import svg from './svg';
import BgVideo from './bgVideo';
import Badges from './badges';

const { ScrollIcon } = svg;

const Section = styled.section`
  padding-bottom: 4rem;
  display: flex;
  flex-flow: column nowrap;

  svg {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    padding-bottom: 0;
    flex-flow: row nowrap;
    padding-top: calc(50vh - 40rem);
    height: calc(100vh - 19.9rem);
    min-height: 60rem;

    svg {
      display: block;
      align-self: flex-end;
      opacity: 0.8;
      flex: 0 0 8%;
    }
  }
`;

const Left = styled.div<{ small?: boolean }>`
  position: relative;
  order: 1;
  display: flex;
  justify-content: center;
  padding-top: 3rem;

  @media screen and (min-width: 1024px) {
    flex: 0 0 46%;
    order: 0;

    &::before {
      display: ${(props) => (props.small ? 'none' : 'block')};
      content: '';
      width: 17rem;
      border: 1.1rem solid white;
      position: absolute;
      top: 12rem;
      left: 10.5rem;
    }
  }

  @media screen and (min-width: 1200px) {
    &::before {
      border-width: 1.2rem;
      top: 20rem;
    }
  }

  @media screen and (min-width: 1600px) {
    &::before {
      border-width: 1.4rem;
      top: 23rem;
    }
  }
`;

const Right = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @media screen and (min-width: 1024px) {
    flex: 0 0 46%;
    align-items: flex-start;
  }
`;

const Title = styled.h1<{ small?: boolean }>`
  font-size: ${(props) => (props.small ? '4.5rem' : '10rem')};
  height: ${(props) => (props.small ? '4rem' : '9rem')};
  text-transform: uppercase;
  font-weight: 500;

  @media screen and (min-width: 768px) {
    font-size: ${(props) => (props.small ? '10rem' : '16rem')};
    height: ${(props) => (props.small ? '9rem' : '14rem')};
  }

  @media screen and (min-width: 1024px) {
    font-size: ${(props) => (props.small ? '5.4rem' : '13rem')};
    height: ${(props) => (props.small ? '6rem' : '13rem')};
  }

  @media screen and (min-width: 1200px) {
    font-size: ${(props) => (props.small ? '6.5rem' : '16.5rem')};
    height: ${(props) => (props.small ? '7.5rem' : '15.5rem')};
  }

  @media screen and (min-width: 1600px) {
    font-size: ${(props) => (props.small ? '9.2rem' : '24rem')};
    height: ${(props) => (props.small ? '10rem' : '23rem')};
  }
`;

const SubTitle = styled.h2<{ small?: boolean }>`
  font-size: ${(props) => (props.small ? '3.3rem' : '6rem')};
  text-transform: uppercase;
  font-weight: normal;

  @media screen and (min-width: 768px) {
    font-size: ${(props) => (props.small ? '7rem' : '12rem')};
  }

  @media screen and (min-width: 1024px) {
    font-size: ${(props) => (props.small ? '3.8rem' : '7.2rem')};
    text-transform: uppercase;
    font-weight: 500;
  }

  @media screen and (min-width: 1200px) {
    font-size: ${(props) => (props.small ? '4.6rem' : '9rem')};
  }

  @media screen and (min-width: 1600px) {
    font-size: ${(props) => (props.small ? '6.6rem' : '12.5rem')};
  }
`;

const Text = styled.p<{ small?: boolean }>`
  margin-top: 3rem;
  width: 30rem;
  font-size: ${(props) => (props.small ? '1.5rem' : '1.8rem')};
  line-height: ${(props) => (props.small ? '2rem' : '4rem')};
  word-spacing: 0.1rem;

  @media screen and (min-width: 768px) {
    font-size: ${(props) => (props.small ? '2.3rem' : '3rem')};
    line-height: ${(props) => (props.small ? '4.2rem' : '6rem')};
    word-spacing: 0.4rem;
    width: 70rem;
  }

  @media screen and (min-width: 1024px) {
    margin-top: 0;
    font-size: ${(props) => (props.small ? '1.6rem' : '1.8rem')};
    line-height: ${(props) => (props.small ? '2.5rem' : '3rem')};
    word-spacing: 0.7rem;
    width: 38rem;
  }

  @media screen and (min-width: 1200px) {
    font-size: ${(props) => (props.small ? '1.8rem' : '2.2rem')};
    width: 46rem;
  }

  @media screen and (min-width: 1600px) {
    width: 64rem;
    font-size: ${(props) => (props.small ? '2.4rem' : '3rem')};
    line-height: 3.4rem;
    word-spacing: 0.5rem;
  }
`;

const BadgesWrapper = styled.div`
  @media screen and (min-width: 1024px) {
    flex: 0 0 20rem;
    position: absolute;
    top: 8rem;
    left: 10.5rem;
  }

  @media screen and (min-width: 1200px) {
    position: absolute;
    top: 16rem;
    left: 10.5rem;
  }

  @media screen and (min-width: 1600px) {
    position: absolute;
    top: 20rem;
    left: 10.5rem;
  }
`;
interface Props {
  title: string;
  subTitle: string;
  text: string;
  novideo?: boolean;
  video?: string;
  poster?: FluidObject;
  small?: boolean;
  badges?: boolean;
  preview?: boolean;
}

const Hero: React.FC<Props> = ({
  title,
  subTitle,
  text,
  small,
  video,
  poster,
  novideo,
  badges,
  preview,
}) => {
  return (
    <Section>
      {!novideo && !preview && (
        <BgVideo video={video as string} poster={poster} />
      )}
      <Left small={small}>
        {badges && (
          <BadgesWrapper>
            <Badges />
          </BadgesWrapper>
        )}
      </Left>
      <ScrollIcon />
      <Right>
        <Title small={small}>{title}</Title>
        <SubTitle small={small}>{subTitle}</SubTitle>
        <Text small={small}>{text}</Text>
      </Right>
    </Section>
  );
};

export default Hero;
