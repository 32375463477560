import React, { ReactNode } from 'react';
import { createGlobalStyle } from 'styled-components';

import Header from './header';
import Footer from './footer';

const GlobalStyle = createGlobalStyle`
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

 body {
    font-size: 1.6rem;
    font-family: montserrat, sans-serif;
    font-display: block;
    background: ${(props: { color: string }) => props.color};
    color: white;
}
`;

interface Props {
  children: ReactNode;
  color: string;
  subnav?: boolean;
  noFooter?: boolean;
  footerColor?: string;
  preview?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  color,
  noFooter,
  footerColor,
  subnav,
  preview,
}) => {
  return (
    <>
      <GlobalStyle color={color} />
      <Header subnav={subnav} preview={preview} />
      <main>{children}</main>
      {!noFooter && <Footer color={footerColor || color} preview={preview} />}
    </>
  );
};

export default Layout;
