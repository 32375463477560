import React from 'react';

const LaptopImage: React.FC = () => {
  return (
    <svg width={741} height={510} viewBox="0 0 741 510" fill="none">
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M674.31 458.261V36.956a36.906 36.906 0 00-10.852-26.132A37.097 37.097 0 00637.26 0H103.74a37.097 37.097 0 00-26.198 10.824A36.91 36.91 0 0066.69 36.956v421.305H0v22.174a29.53 29.53 0 008.681 20.906A29.68 29.68 0 0029.64 510h681.72c7.861 0 15.4-3.115 20.959-8.659A29.531 29.531 0 00741 480.435v-22.174h-66.69z"
          fill="#1A3648"
        />
        <path d="M103.74 36.957h533.52v376.956H103.74V36.957z" fill="#3498DB" />
        <path d="M66.69 443.479h607.62v14.782H66.69v-14.782z" fill="#214B66" />
        <path
          d="M552.045 473.044a11.134 11.134 0 00-7.861 3.251 11.082 11.082 0 00-3.254 7.843 11.068 11.068 0 003.259 7.841 11.118 11.118 0 007.863 3.246 11.13 11.13 0 007.861-3.251 11.073 11.073 0 00-.005-15.684 11.134 11.134 0 00-7.863-3.246zm37.05 0a11.134 11.134 0 00-7.861 3.251 11.082 11.082 0 00-3.254 7.843 11.068 11.068 0 003.259 7.841 11.118 11.118 0 007.863 3.246 11.13 11.13 0 007.861-3.251 11.073 11.073 0 00-.005-15.684 11.134 11.134 0 00-7.863-3.246zm37.05 0a11.134 11.134 0 00-7.861 3.251 11.082 11.082 0 00-3.254 7.843 11.068 11.068 0 003.259 7.841 11.118 11.118 0 007.863 3.246 11.13 11.13 0 007.861-3.251 11.073 11.073 0 00-.005-15.684 11.134 11.134 0 00-7.863-3.246z"
          fill="#BCC4C8"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h741v510H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LaptopImage;
