import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FaRegWindowClose, FaChevronRight } from 'react-icons/fa';
import { MdSubdirectoryArrowRight } from 'react-icons/md';

import AniLink from 'gatsby-plugin-transition-link/AniLink';

const NavMenu = styled.nav<{ open: boolean }>`
  position: fixed;
  transform: ${(props) => (props.open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 1s 0s;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  background: #32213a;

  & > svg {
    font-size: 8.5rem;
    margin-left: auto;
    padding: 2rem;
    display: block;
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const Links = styled.ul`
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  li {
    list-style: none;
    margin-left: 2rem;

    .active {
      opacity: 1;
    }

    a {
      color: white;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 3rem;
      font-weight: bold;
      text-align: left;
      display: block;
      padding: 1.5rem;
      opacity: 0.5;

      & > svg {
        display: block;
        font-size: 6rem;
      }
    }
  }

  div {
    text-align: left;
    width: 18rem;
    position: relative;
    top: -4rem;
    left: 7rem;

    .active {
      opacity: 1;
    }
  }

  div > a {
    display: block;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 2rem;
    padding: 1rem;
    opacity: 0.5;
  }
`;

interface Props {
  open: boolean;
  toggleMobileNav: () => void;
  preview?: boolean;
}

const MobileNav: React.FC<Props> = ({ open, toggleMobileNav, preview }) => {
  return (
    <NavMenu open={open}>
      <FaRegWindowClose onClick={toggleMobileNav} />
      {!preview ? (
        <Links>
          <li>
            <AniLink
              cover
              direction="left"
              bg="#0A469A"
              duration={1.4}
              to="/"
              onClick={toggleMobileNav}
              activeClassName="active"
            >
              home
            </AniLink>
          </li>
          <li>
            <AniLink
              cover
              direction="left"
              bg="#A13D0B"
              duration={1.4}
              to="/about"
              onClick={toggleMobileNav}
              activeClassName="active"
            >
              about
            </AniLink>
          </li>
          <li>
            <AniLink
              cover
              direction="left"
              duration={1.4}
              bg="#0C235F"
              to="/services"
              onClick={toggleMobileNav}
              activeClassName="active"
              partiallyActive
            >
              services
              <MdSubdirectoryArrowRight />
            </AniLink>
          </li>
          <div>
            <AniLink
              cover
              direction="left"
              duration={1.4}
              bg="#190821"
              to="/services/passive"
              onClick={toggleMobileNav}
              activeClassName="active"
            >
              <FaChevronRight />
              passive infrastructure
            </AniLink>
            <AniLink
              cover
              direction="left"
              duration={1.4}
              bg="#190821"
              to="/services/active"
              onClick={toggleMobileNav}
              activeClassName="active"
            >
              <FaChevronRight />
              active infrastructure
            </AniLink>
            <AniLink
              cover
              direction="left"
              duration={1.4}
              bg="#190821"
              to="/services/cloud"
              onClick={toggleMobileNav}
              activeClassName="active"
            >
              <FaChevronRight />
              cloud services
            </AniLink>
            <AniLink
              cover
              direction="left"
              duration={1.4}
              bg="#190821"
              to="/services/consulting"
              onClick={toggleMobileNav}
              activeClassName="active"
            >
              <FaChevronRight />
              consultancy services
            </AniLink>
          </div>
          <li>
            <AniLink
              cover
              direction="left"
              duration={1.4}
              bg="#941B25"
              to="/contact"
              onClick={toggleMobileNav}
              activeClassName="active"
            >
              contact
            </AniLink>
          </li>
        </Links>
      ) : (
        <Links>
          <li>
            <Link to="/" onClick={toggleMobileNav} activeClassName="active">
              home
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              onClick={toggleMobileNav}
              activeClassName="active"
            >
              about
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              onClick={toggleMobileNav}
              activeClassName="active"
              partiallyActive
            >
              services
              <MdSubdirectoryArrowRight />
            </Link>
          </li>
          <div>
            <Link
              to="/services/passive"
              onClick={toggleMobileNav}
              activeClassName="active"
            >
              <FaChevronRight />
              passive infrastructure
            </Link>
            <Link
              to="/services/active"
              onClick={toggleMobileNav}
              activeClassName="active"
            >
              <FaChevronRight />
              active infrastructure
            </Link>
            <Link
              to="/services/cloud"
              onClick={toggleMobileNav}
              activeClassName="active"
            >
              <FaChevronRight />
              cloud services
            </Link>
            <Link
              to="/services/consulting"
              onClick={toggleMobileNav}
              activeClassName="active"
            >
              <FaChevronRight />
              consultancy services
            </Link>
          </div>
          <li>
            <Link
              to="/contact"
              onClick={toggleMobileNav}
              activeClassName="active"
            >
              contact
            </Link>
          </li>
        </Links>
      )}
    </NavMenu>
  );
};

export default MobileNav;
