import React from 'react';
import styled, { keyframes } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Link } from 'gatsby';

const FadeIn = keyframes`
from {
  opacity: 0;
}

to {opacity: 1;}
`;

const List = styled.ul`
  display: none;

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    padding: 2.1rem 1rem;
    width: 50rem;

    li {
      list-style: none;
      padding: 1rem;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 2.2rem;

      a {
        color: white;
        opacity: 0.8;
        text-decoration: none;
        border-bottom: 0.3rem solid transparent;
        transition: all 0.2s;

        &:hover {
          border-bottom: 0.3rem solid white;
        }
      }

      .active {
        border-bottom: 0.3rem solid white;
        opacity: 1;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    width: 60rem;
  }

  @media screen and (min-width: 1600px) {
    width: 84rem;
  }
`;

const SubList = styled.ul`
  display: none;

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: center;
    padding: 0 1rem;
    width: 50rem;
    animation: ${FadeIn} 0.7s ease-in-out;

    li {
      list-style: none;
      padding: 0 1rem;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 1.3rem;

      .active {
        opacity: 1;
        border-bottom: 0.1rem solid white;
      }

      a {
        color: white;
        display: block;
        opacity: 0.7;
        text-decoration: none;
        border-bottom: 0.3rem solid transparent;
        transition: all 0.2s;
        text-align: center;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    width: 60rem;
  }

  @media screen and (min-width: 1600px) {
    width: 55rem;
    margin: 0 auto;
  }
`;

interface Props {
  subnav?: boolean;
  preview?: boolean;
}

const Navbar: React.FC<Props> = ({ subnav, preview }) => {
  return (
    <nav>
      {!preview ? (
        <>
          <List>
            <li>
              <AniLink
                cover
                direction="down"
                bg="#0A469A"
                duration={1.2}
                to="/"
                activeClassName="active"
              >
                home
              </AniLink>
            </li>
            <li>
              <AniLink
                cover
                direction="up"
                bg="#A13D0B"
                duration={1.2}
                to="/about"
                activeClassName="active"
              >
                about
              </AniLink>
            </li>
            <li>
              <AniLink
                cover
                direction="down"
                bg="#0C235F"
                duration={1.2}
                to="/services"
                activeClassName="active"
                partiallyActive
              >
                services
              </AniLink>
            </li>
            <li>
              <AniLink
                cover
                direction="up"
                bg="#941B25"
                duration={1.2}
                to="/contact"
                activeClassName="active"
              >
                contact
              </AniLink>
            </li>
          </List>
          {subnav && (
            <SubList>
              <li>
                <AniLink
                  cover
                  direction="left"
                  duration={1.2}
                  bg="#190821"
                  to="/services/passive"
                  activeClassName="active"
                >
                  passive infrastructure
                </AniLink>
              </li>
              <li>
                <AniLink
                  cover
                  direction="left"
                  duration={1.2}
                  bg="#190821"
                  to="/services/active"
                  activeClassName="active"
                >
                  active infrastructure
                </AniLink>
              </li>
              <li>
                <AniLink
                  cover
                  direction="left"
                  duration={1.2}
                  bg="#190821"
                  to="/services/cloud"
                  activeClassName="active"
                >
                  cloud services
                </AniLink>
              </li>
              <li>
                <AniLink
                  cover
                  direction="left"
                  duration={1.2}
                  bg="#190821"
                  to="/services/consulting"
                  activeClassName="active"
                >
                  consultancy services
                </AniLink>
              </li>
            </SubList>
          )}
        </>
      ) : (
        <>
          <List>
            <li>
              <Link to="/" activeClassName="active">
                home
              </Link>
            </li>
            <li>
              <Link to="/about" activeClassName="active">
                about
              </Link>
            </li>
            <li>
              <Link to="/services" activeClassName="active" partiallyActive>
                services
              </Link>
            </li>
            <li>
              <Link to="/contact" activeClassName="active">
                contact
              </Link>
            </li>
          </List>
          {subnav && (
            <SubList>
              <li>
                <Link to="/services/passive" activeClassName="active">
                  passive infrastructure
                </Link>
              </li>
              <li>
                <Link to="/services/active" activeClassName="active">
                  active infrastructure
                </Link>
              </li>
              <li>
                <Link to="/services/cloud" activeClassName="active">
                  cloud services
                </Link>
              </li>
              <li>
                <Link to="/services/consulting" activeClassName="active">
                  consultancy services
                </Link>
              </li>
            </SubList>
          )}
        </>
      )}
    </nav>
  );
};

export default Navbar;
