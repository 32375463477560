import * as React from 'react';

const ScrollIcon: React.FC = () => {
  return (
    <svg width={110} height={110} viewBox="0 0 110 110" fill="none">
      <title>Scroll Down</title>
      <path
        d="M56.981 25.625h-3.776c-8.977 0-16.323 7.346-16.323 16.323v25.87c0 8.977 7.346 16.323 16.323 16.323h3.776c8.978 0 16.323-7.346 16.323-16.323v-25.87c0-8.977-7.345-16.323-16.323-16.323z"
        fill="#D0CFCE"
      />
      <path
        d="M55.093 33.782a3.178 3.178 0 00-3.168 3.169v8.913a3.178 3.178 0 003.168 3.167 3.178 3.178 0 003.169-3.167V36.95a3.177 3.177 0 00-3.169-3.169z"
        fill="#3F3F3F"
      />
      <path
        d="M53.68 27.77c4.625 1.985 9.02 4.833 9.532 12.029.593 8.334-2.49 23.938-3.483 31.864-.693 5.542-3.433 8.108-7.074 11.016-1.833 1.468 8.047 1.29 12.115-1.09 3.493-2.04 6.875-5.544 6.916-4.436l.494-39.425S70.749 27.13 59.093 27.126c0 0-11.69-.6-5.413.644z"
        fill="#9B9B9A"
      />
      <path
        d="M59.324 83.265h-8.46c-7.593 0-13.803-6.21-13.803-13.802v-29.16c0-7.592 6.212-13.802 13.803-13.802h8.458c7.593 0 13.803 6.21 13.803 13.801v29.161c0 7.592-6.211 13.802-13.803 13.802h.002z"
        stroke="#000"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.222 49.126h-.257a3.109 3.109 0 01-3.102-3.102V36.79a3.11 3.11 0 013.102-3.1h.257a3.11 3.11 0 013.101 3.101v9.237a3.112 3.112 0 01-3.101 3.102v-.003zM63.144 91.69l-7.968 7.97M47.143 91.69l7.968 7.97M47.143 18.168L55.11 10.2M63.144 18.168L55.176 10.2"
        stroke="#000"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ScrollIcon;
