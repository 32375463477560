import React from 'react';
import styled from 'styled-components';

const StyledBanner = styled.section<{ color: string }>`
  background: ${(props) => props.color};
  position: relative;
  margin-bottom: 5rem;

  h1 {
    text-transform: uppercase;
    text-align: center;
    padding: 3rem;
    font-weight: normal;
    font-size: 3.3rem;
  }

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    display: block;
    border-right: 3rem solid transparent;
    border-left: 3rem solid transparent;
    border-top: 5rem solid ${(props) => props.color};
    left: 50%;
    transform: translate(-50%);
  }
`;

interface Props {
  title: string;
  color: string;
  className?: string;
}

const Banner: React.FC<Props> = ({ title, color, className }) => {
  return (
    <>
      <StyledBanner color={color} className={className}>
        <h1>{title}</h1>
      </StyledBanner>
    </>
  );
};

export default Banner;
