import React from 'react';
import styled from 'styled-components';

import Card, { Props as CardProps } from './card';

const StyledSection = styled.section<{ color: string; small?: boolean }>`
  color: ${(props) => props.color};

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: ${(props) =>
      props.small ? '1fr 1fr 1fr' : '1fr 1fr'};
  }

  @media screen and (min-width: 1200px) {
    display: grid;
    grid-template-columns: ${(props) =>
      props.small ? '1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
  }
`;

interface Props {
  color: string;
  cardsData: CardProps[];
  small?: boolean;
}

const Cards: React.FC<Props> = ({ color, cardsData, small }) => {
  const cards = cardsData.map((cardData) => (
    <Card
      key={cardData.title}
      title={cardData.title}
      text={cardData.text}
      small={small}
    >
      {cardData.children}
    </Card>
  ));

  return (
    <StyledSection color={color} small={small}>
      {cards}
    </StyledSection>
  );
};

export default Cards;
