import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div<{ small?: boolean }>`
  min-height: ${(props) => (props.small ? '48rem' : '65rem')};
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  @media screen and (min-width: 1024px) {
    padding: 8rem 0;
    justify-content: flex-start;
    margin-top: 0;
  }

  div {
    width: 13rem;
    height: 13rem;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px) {
      width: 15rem;
      height: 15rem;
    }

    @media screen and (min-width: 1024px) {
      width: 13rem;
      height: 13rem;
    }

    @media screen and (min-width: 1600px) {
      width: 18rem;
      height: 18rem;
    }

    svg {
      font-size: 9rem;

      @media screen and (min-width: 768px) {
        font-size: 11rem;
      }

      @media screen and (min-width: 1024px) {
        font-size: 8rem;
      }

      @media screen and (min-width: 1600px) {
        font-size: 10rem;
      }
    }
  }

  h1 {
    font-weight: 600;
    text-transform: uppercase;
    padding: 3rem 0;
    text-align: center;
    width: 19rem;
    color: white;

    @media screen and (min-width: 768px) {
      font-size: 3rem;
      width: 24rem;
    }

    @media screen and (min-width: 1024px) {
      font-weight: 500;
      font-size: 2.2rem;
      width: 17rem;
    }

    @media screen and (min-width: 1200px) {
      font-size: 2rem;
      width: 16rem;
    }

    @media screen and (min-width: 1600px) {
      font-size: 2.8rem;
      width: 22rem;
    }
  }

  h1::after {
    content: '';
    padding-top: 3rem;
    display: block;
    width: 8rem;
    margin: 0 auto;
    border-bottom: 0.5rem solid white;
  }

  p {
    padding: 1rem 0;
    width: 90%;
    line-height: 2.5rem;
    font-weight: 600;
    color: white;

    @media screen and (min-width: 768px) {
      width: 70%;
      line-height: 3.5rem;
      font-size: 2.2rem;
      font-weight: normal;
    }

    @media screen and (min-width: 1024px) {
      width: ${(props) => (props.small ? '22rem' : '34rem')};
      line-height: 2.4rem;
      font-size: 1.6rem;
    }

    @media screen and (min-width: 1200px) {
      width: ${(props) => (props.small ? '25rem' : '20rem')};
    }

    @media screen and (min-width: 1600px) {
      width: ${(props) => (props.small ? '38rem' : '30rem')};
      font-size: 1.8rem;
    }
  }
`;

export interface Props {
  children: React.ReactNode;
  title: string;
  text: string;
  small?: boolean;
}

const Card: React.FC<Props> = ({ children, title, text, small }) => {
  return (
    <StyledCard small={small}>
      <div>{children}</div>
      <h1>{title}</h1>
      <p>{text}</p>
    </StyledCard>
  );
};

export default Card;
