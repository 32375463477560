import React, { useState } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import styled from 'styled-components';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { Link } from 'gatsby';

import svg from '../svg';
import Navbar from './navbar';
import MobileNav from './mobileNav';

const { Logo } = svg;

const StyledHeader = styled.header`
  position: relative;

  & > a {
    display: inline-block;
    padding-left: 3rem;

    svg {
      display: block;
      width: 15rem;
      height: auto;
      padding: 3rem 0;
    }
  }

  > svg:nth-child(3) {
    font-size: 3rem;
    position: absolute;
    top: 2.5rem;
    right: 2rem;
  }

  @media screen and (min-width: 768px) {
    a {
      svg {
        width: 17rem;
      }
    }

    > svg:nth-child(3) {
      font-size: 4rem;
    }
  }

  @media screen and (min-width: 1024px) {
    margin: 3rem 10rem;
    display: flex;
    justify-content: space-between;

    a {
      svg {
        margin: 0;
        flex: 0 0 auto;
      }
    }

    > svg:nth-child(3) {
      display: none;
    }
  }
`;

interface Props {
  subnav?: boolean;
  preview?: boolean;
}
const Header: React.FC<Props> = ({ subnav, preview }) => {
  const [open, setOpen] = useState(false);

  const toggleMobileNav = (): void => {
    setOpen(!open);
  };

  return (
    <StyledHeader>
      <MobileNav
        open={open}
        toggleMobileNav={toggleMobileNav}
        preview={preview}
      />
      {!preview ? (
        <AniLink
          paintDrip
          direction="right"
          hex="#0A469A"
          duration={1.2}
          to="/"
        >
          <Logo title="Compute Square | Home" />
        </AniLink>
      ) : (
        <Link to="/">
          <Logo title="Compute Square | Home" />
        </Link>
      )}
      <AiOutlineMenuFold onClick={toggleMobileNav} />
      <Navbar subnav={subnav} preview={preview} />
    </StyledHeader>
  );
};

export default Header;
