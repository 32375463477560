import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styled from 'styled-components';
import { FaFacebookF, FaTwitter, FaGithub } from 'react-icons/fa';
import { Link } from 'gatsby';

import svg from '../svg';

const { Logo } = svg;

const SocialIcons = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;

  div {
    width: 4rem;
    height: 4rem;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.2);
    }

    @media screen and (min-width: 1024px) {
      width: 4.5rem;
      height: 4.5rem;
    }

    svg {
      font-size: 2.4rem;
      color: ${(props) => props.color};

      @media screen and (min-width: 1024px) {
        font-size: 3rem;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    order: 1;
    flex: 0 0 18rem;
  }
`;

const StyledFooter = styled.footer<{ color: string }>`
  background-color: ${(props) => props.color};

  & > a {
    > svg {
      width: 17rem;
      height: auto;
      margin: 0 auto;
      display: block;
      padding: 2rem 0;

      @media screen and (min-width: 1024px) {
        flex: 0 0 18rem;
        margin: 0;
      }
    }
  }

  p {
    text-transform: uppercase;
    text-align: center;
    font-size: 1.2rem;
    padding: 0.8rem 0;
  }

  @media screen and (min-width: 1024px) {
    padding: 3rem 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const ContactInfo = styled.div`
  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

interface Props {
  color: string;
  preview?: boolean;
}

const Footer: React.FC<Props> = ({ color, preview }) => {
  return (
    <StyledFooter color={color}>
      {!preview ? (
        <AniLink paintDrip hex="#0A469b" duration={1.3} to="/">
          <Logo title="Compute Square | Home" />
        </AniLink>
      ) : (
        <Link to="/">
          <Logo title="Compute Square | Home" />
        </Link>
      )}
      <SocialIcons color={color}>
        <div>
          <OutboundLink
            href="https://facebook.com/computesquare"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF title="Facebook" />
          </OutboundLink>
        </div>
        <div>
          <OutboundLink
            href="https://twitter.com/computesquare"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter title="Twitter" />
          </OutboundLink>
        </div>
        <div>
          <OutboundLink
            href="https://github.com/computesquare"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub title="Github" />
          </OutboundLink>
        </div>
      </SocialIcons>
      <ContactInfo>
        <p>info@computesquare.com</p>
        <p>Call us: +20 12 0000 3535</p>
        <p>© All Rights Reserved to Compute Square</p>
      </ContactInfo>
    </StyledFooter>
  );
};

export default Footer;
