import * as React from 'react';

const Pointer: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      width={239}
      height={106}
      viewBox="0 0 239 106"
      fill="none"
    >
      <path stroke="#fff" strokeWidth={2} d="M111.684.729l-97 91" />
      <circle cx={11.5} cy={94.5} r={11.5} fill="#fff" />
      <path stroke="#fff" strokeWidth={2} d="M111 1h128" />
    </svg>
  );
};

export default Pointer;
