import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

const Badges: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      google: file(relativePath: { eq: "badges/google.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      sophos: file(relativePath: { eq: "badges/sophos.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      webroot: file(relativePath: { eq: "badges/webroot.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      microsoft: file(relativePath: { eq: "badges/microsoft.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      godaddy: file(relativePath: { eq: "badges/godaddy.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 70) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <Carousel
      autoPlay
      width={200 as any}
      interval={4000}
      dynamicHeight
      infiniteLoop
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      transitionTime={1200}
    >
      <div>
        <Img
          fixed={data.google.childImageSharp.fixed}
          alt="Google Reseller Logo"
        />
      </div>
      <div>
        <Img fixed={data.sophos.childImageSharp.fixed} alt="Sophos Logo" />
      </div>
      <div>
        <Img fixed={data.webroot.childImageSharp.fixed} alt="Webroot Logo" />
      </div>
      <div>
        <Img
          fixed={data.microsoft.childImageSharp.fixed}
          alt="Microsoft Logo"
        />
      </div>
      <div>
        <Img fixed={data.godaddy.childImageSharp.fixed} alt="Godaddy Logo" />
      </div>
    </Carousel>
  );
};

export default Badges;
